:root {
  font-size: 62.5%;
  body {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.3rem;
    &.modal-open {
      overflow: hidden;
      position: fixed;
      width: 100%;
      background-color: #f7f7fe;
    }
    &.modal-open-padding {
      padding-right: 2px;
    }
  }

  body::-webkit-scrollbar, 
  .scroll-content::-webkit-scrollbar,
  .card-body::-webkit-scrollbar, 
  .tabs__content::-webkit-scrollbar,
  .page-sidebar::-webkit-scrollbar,
  .modal::-webkit-scrollbar,
  select::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  body::-webkit-scrollbar-thumb, 
  .scroll-content::-webkit-scrollbar-thumb,
  .card-body::-webkit-scrollbar-thumb,
  .tabs__content::-webkit-scrollbar-thumb,
  .page-sidebar::-webkit-scrollbar-thumb,
  .modal::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    outline: 1px solid #adb5bd;
    border-radius: 1px;
  }

  .page-content {
    position: relative;
  }
  
  .form-control {
    font-size: 1.3rem;
  }
  .modal-dialog.modal-md {
    padding-bottom: 100px;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .page-title {
    font-size: 2rem;
    line-height: 1.2;
    color: #343a40;
    font-weight: 500;
    margin: 0;
  }

  .page-subtitle {
    color: #343a40;
  }

  .card .card-header {
    padding: 1.3rem 1.5rem;
  }

  .cell-link {
    color: #5D78FF;
    font-weight: normal;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;

    &:hover {
      opacity: 0.8;
    }
  }

  .cell-status {
    display: inline-block;
    padding: 0.4rem 0.6rem;
    min-width: 6rem;
    font-size: .9rem;
    line-height: .9rem;
    text-align: center;
    border: .1rem solid;
    border-radius: 0.4rem;
    text-transform: capitalize;

    &_online,
    &_success,
    &_approved,
    &_created {
      $green: #64DD17;
      color: $green;
      border-color: $green;
    }

    &_awaiting_approval {
      $orange: #f49917;;
      color: $orange;
      border-color: $orange;
    }

    &_offline,
    &_partly_created,
    &_rejected,
    &_failed {
      $red: #EF1C1C;
      color: $red;
      border-color: $red;
    }

    &_not-responding {
      $gray: #85ADB5;
      color: $gray;
      border-color: $gray;
    }

    &_pending {
      $pending: #2a89b8;
      color: $pending;
      border-color: $pending;
    }

    &_completed {
      $completed: #6e919f;
      color: $completed;
      border-color: $completed;
    }
  }

  .btn-outline-dark {
    &:hover,
    &:focus {
      .btn-icon {
        fill: #fff;
      }
    }
  }

  .bg-card {
    background-color: #fff;
  }

  .width-100 {
    width: 100%;
  }

  .modal {
    overflow-y: auto;
  }
}