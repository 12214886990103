.Toastify {
  font-size: 1.3rem;

  &__toast {
    padding: 1.2rem 2rem;
    border: 1px solid transparent;
    border-radius: .4rem;
    min-height: auto;

    &-body {
      margin: 0;
    }

    &--default {
      color: #004085;
      background-color: #cce5ff;
      border-color: #b8daff;
    }
    
    &--info {
      color: #0c5460;
      background-color: #d1ecf1;
      border-color: #bee5eb;
    }
    
    &--success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
    
    &--warning {
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
    }
    
    &--error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }
  }
}