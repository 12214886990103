.facebookAudienceSize {
	padding: 20px;
	margin-bottom: 16px;
	border: 1px solid rgb(203, 214, 226);
	background-color: rgb(255, 255, 255);
	text-align: left;

	&__title {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #33475b;
		-webkit-font-smoothing: antialiased;
		margin-bottom: 16px;
	}

	&__content {
		font-weight: 400;
		font-size: 24px;
		line-height: 29px;
		color: #33475b;
		margin-bottom: 0;
	}
}

.facebookPagePreview {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	border: 1px solid rgb(203, 214, 226);
	background-color: rgb(255, 255, 255);

	&__header,
	&__footer {
		padding: 8px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}

	&__logo {
		flex-shrink: 0;
		display: flex;
		width: 38.5px;
		height: 38.5px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 11.5px 0 0;
		background: rgb(255, 143, 89);
	}

	&__title {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: rgb(0, 164, 189);
		margin: 0 0 5px;
	}

	&__position {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: #516f90;
		margin: 0;
	}

	&__description {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #33475b;
		margin: 0;
		text-align: left;
		word-break: break-all;
	}

	&__body img,
	&__logo img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__body {
		height: 225px;
		background: rgb(223, 227, 235);
		border-top: 1px solid rgb(203, 214, 226);
		border-bottom: 1px solid rgb(203, 214, 226);
		margin: 0;
		word-break: break-all;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		background: rgb(234, 240, 246);
	}

	&__content {
		flex: 1;
		text-align: left;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #33475b;
		margin: 0;
		word-break: break-all;
	}

	&__button {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		padding: 4px 12px;
		height: 30px;
		border: 2px solid rgb(203, 214, 226);
		color: #506e91;
		background-color: #eaf0f6;
		margin: 0 0 0 10px;
	}
}