.auth-form-wrap {
  width: 300px;
  padding: 38px 20px;
  border: 1px solid rgba(230, 230, 230, 0.7);
  border-radius: 3px;

  &__title {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 18px;
    font-size: 1.3rem;
    line-height: 1.9rem;
  }
}
